import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { doc, onSnapshot } from 'firebase/firestore';

const Counter = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const counterRef = doc(db, 'counters', 'miaouwCounter');
    const unsubscribe = onSnapshot(counterRef, (doc) => {
      if (doc.exists()) {
        setCount(doc.data().count);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div id="miaouwCounter">Miaouw Count: {count}</div>
  );
};

export default Counter;