import React, { useState, useEffect } from 'react';
import Counter from './Counter';
import './App.css';
import { db } from './firebaseConfig';
import { doc, updateDoc, increment } from 'firebase/firestore';

const apis = [
  'https://api.thecatapi.com/v1/images/search'
  //'https://nekos.best/api/v2/neko'
];

function App() {
  const [currentApi, setCurrentApi] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);

  const fetchImage = async () => {
    try {
      setLoading(true);
      const response = await fetch(apis[currentApi]);
      const data = await response.json();
      setImageUrl(currentApi === 0 ? data[0].url : data.results[0].url);
      setCurrentApi((prev) => (prev + 1) % apis.length);
    } catch (error) {
      console.error('load miaouw failed:', error);
      alert("you Mew Mew too fast!");
    } finally {
      setLoading(false);
    }
  };

  const incrementCounter = async () => {
    try {
      const counterRef = doc(db, 'counters', 'miaouwCounter');
      await updateDoc(counterRef, {
        count: increment(1)
      });
      console.log('miaouw++');
    } catch (error) {
      console.error('miaouw panic', error);
    }
  };

  const handleMiaouwClick = async () => {
    await fetchImage();
    await incrementCounter();
  };

  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  useEffect(() => {
    fetchImage(); 
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--bg-color', isDarkMode ? '#1a1a1a' : '#f0f0f0');
    document.documentElement.style.setProperty('--text-color', isDarkMode ? '#ffffff' : '#000000');
  }, [isDarkMode]);

  return (
    <div className="container">
      <h1>Miaouw</h1>
      <div className="image-container">
        {imageUrl && <img id="miaouwImg" src={imageUrl} alt="Random miaouw" />}
        {loading && (
          <div className="loading-overlay">
            <i className="fas fa-spinner fa-spin loading-spinner"></i>
          </div>
        )}
      </div>
      <button id="miaouwButton" onClick={handleMiaouwClick}>miaouw ?!</button>
      <Counter />
      <button className="ds-toggle" onClick={toggleDarkMode}>
        <i className={`fas ${isDarkMode ? 'fa-sun' : 'fa-moon'}`}></i>
      </button>
    </div>
  );
}

export default App;